.wmde-markdown {
  font-size: 16px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
.wmde-markdown > :first-child {
  margin-top: 0 !important;
}
.wmde-markdown > :last-child {
  margin-bottom: 0 !important;
}
.wmde-markdown code[class*="language-"],
.wmde-markdown pre[class*="language-"] {
  color: black;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
          hyphens: none;
}
.wmde-markdown pre code {
  background: none;
  overflow-wrap: normal;
  white-space: inherit;
  -moz-tab-size: 2;
       tab-size: 2;
  padding: 16px;
  font-size: 95%;
  line-height: 1.5;
  display: block;
  text-shadow: 0 1px #fff;
}
.wmde-markdown pre {
  margin-bottom: 18px;
  font-size: 85%;
  line-height: 1.45;
  position: relative;
  overflow-x: auto;
  background-color: #f6f8fa;
  border-radius: 3px;
}
.wmde-markdown code,
.wmde-markdown tt {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  font-size: 85%;
  margin: 0;
  padding: 0.2em 0.4em;
}
.wmde-markdown pre,
.wmde-markdown code,
.wmde-markdown tt {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
}
.wmde-markdown img {
  max-width: 100%;
}
.wmde-markdown input {
  vertical-align: middle;
  margin: 0 0.2em 0.25em -1.6em;
}
.wmde-markdown input + p {
  display: inline;
}
.wmde-markdown h1,
.wmde-markdown h2 {
  border-bottom: 1px solid #eaecef;
  padding-bottom: 0.3em;
}
.wmde-markdown h1,
.wmde-markdown h2,
.wmde-markdown h3,
.wmde-markdown h4,
.wmde-markdown h5,
.wmde-markdown h6 {
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 16px;
  margin-top: 24px;
}
.wmde-markdown h1 .anchor,
.wmde-markdown h2 .anchor,
.wmde-markdown h3 .anchor,
.wmde-markdown h4 .anchor,
.wmde-markdown h5 .anchor,
.wmde-markdown h6 .anchor {
  float: left;
  padding-right: 4px;
  margin-left: -20px;
  line-height: 1;
}
.wmde-markdown h1 .octicon-link,
.wmde-markdown h2 .octicon-link,
.wmde-markdown h3 .octicon-link,
.wmde-markdown h4 .octicon-link,
.wmde-markdown h5 .octicon-link,
.wmde-markdown h6 .octicon-link {
  visibility: hidden;
  vertical-align: middle;
}
.wmde-markdown h1:hover .octicon-link,
.wmde-markdown h2:hover .octicon-link,
.wmde-markdown h3:hover .octicon-link,
.wmde-markdown h4:hover .octicon-link,
.wmde-markdown h5:hover .octicon-link,
.wmde-markdown h6:hover .octicon-link {
  visibility: visible;
}
.wmde-markdown h1 {
  font-size: 2em;
}
.wmde-markdown h2 {
  font-size: 1.5em;
}
.wmde-markdown h3 {
  font-size: 1.25em;
}
.wmde-markdown h4 {
  font-size: 1em;
}
.wmde-markdown h5 {
  font-size: 0.875em;
}
.wmde-markdown h6 {
  font-size: 0.85em;
}
.wmde-markdown ol,
.wmde-markdown ul {
  padding-left: 2em;
}
.wmde-markdown ol > p,
.wmde-markdown ul > p {
  margin-bottom: 0;
}
.wmde-markdown ul {
  margin-bottom: 16px;
  margin-top: 0;
  list-style: initial;
}
.wmde-markdown > blockquote,
.wmde-markdown > blockquote blockquote {
  margin: 0;
  border-left: 0.25em solid #dfe2e5;
  color: #6a737d;
  padding: 0 1em;
}
.wmde-markdown > blockquote > :last-child,
.wmde-markdown > blockquote blockquote > :last-child {
  margin-bottom: 0;
}
.wmde-markdown > blockquote > :first-child,
.wmde-markdown > blockquote blockquote > :first-child {
  margin-top: 0;
}
.wmde-markdown hr {
  margin: 1.5em auto;
  border: 0;
  border-top: 2px dotted #eee;
  height: 1px;
}
.wmde-markdown > table,
.wmde-markdown > blockquote table {
  display: block;
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
}
.wmde-markdown > table tr,
.wmde-markdown > blockquote table tr {
  background-color: #fff;
  border-top: 1px solid #c6cbd1;
}
.wmde-markdown > table td,
.wmde-markdown > blockquote table td,
.wmde-markdown > table th,
.wmde-markdown > blockquote table th {
  border: 1px solid #dfe2e5;
  padding: 6px 13px;
}
.wmde-markdown blockquote,
.wmde-markdown details,
.wmde-markdown dl,
.wmde-markdown ol,
.wmde-markdown p,
.wmde-markdown pre,
.wmde-markdown table,
.wmde-markdown ul {
  margin-bottom: 16px;
  margin-top: 0;
}
.wmde-markdown a {
  color: #0366d6;
  text-decoration: none;
}
.wmde-markdown a:hover {
  text-decoration: underline;
}
.wmde-markdown .namespace {
  opacity: 0.7;
}
.wmde-markdown .token.important {
  font-weight: normal;
}
.wmde-markdown .token.bold {
  font-weight: bold;
}
.wmde-markdown .token.italic {
  font-style: italic;
}
.wmde-markdown .token.entity {
  cursor: help;
}
